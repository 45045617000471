import {createRouter, createWebHashHistory} from 'vue-router'
import {getSession, removeSession} from "@/utils/util";
import {Check} from "@/api/common";

const routes = [
  {
    path: '/',
    name: 'Waymon',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import ('../views/Home.vue'),
    meta: {
      title: '首页'
    }
  }, {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      title: '登录'
    }
  }, {
    path: '/Admin',
    name: 'Admin',
    component: () => import ('../views/Admin.vue'),
    meta: {
      title: '管理员',
    }
  }, {
    path: '/AdminAdd',
    name: 'AdminAdd',
    component: () => import ('../views/AdminAdd.vue'),
    meta: {
      title: '添加管理员'
    }
  }, {
    path: '/AdminEdit',
    name: 'AdminEdit',
    component: () => import ('../views/AdminEdit.vue'),
    meta: {
      title: '管理员编辑'
    }
  }, {
    path: '/Amount',
    name: 'Amount',
    component: () => import ('../views/Amount.vue'),
    meta: {
      title: '账户列表',
    }
  },{
    path: '/Authen',
    name: 'Authen',
    component: () => import ('../views/Authen.vue'),
    meta: {
      title: '实名认证',
    }
  },{
    path: '/AuthenEdit',
    name: 'AuthenEdit',
    component: () => import ('../views/AuthenEdit.vue'),
    meta: {
      title: '编辑实名',
    }
  },{
    path: '/Banner',
    name: 'Banner',
    component: () => import ('../views/Banner.vue'),
    meta: {
      title: '轮播图列表',
    }
  },{
    path: '/BannerAdd',
    name: 'BannerAdd',
    component: () => import ('../views/BannerAdd.vue'),
    meta: {
      title: '添加轮播图',
    }
  },{
    path: '/BannerEdit',
    name: 'BannerEdit',
    component: () => import ('../views/BannerEdit.vue'),
    meta: {
      title: '轮播图编辑',
    }
  }, {
    path: '/Brand',
    name: 'Brand',
    component: () => import ('../views/Brand.vue'),
    meta: {
      title: '品牌列表',
    }
  }, {
    path: '/BrandAdd',
    name: 'BrandAdd',
    component: () => import ('../views/BrandAdd.vue'),
    meta: {
      title: '添加品牌',
    }
  }, {
    path: '/BrandEdit',
    name: 'BrandEdit',
    component: () => import ('../views/BrandEdit.vue'),
    meta: {
      title: '品牌编辑',
    }
  },  {
    path: '/Cami',
    name: 'Cami',
    component: () => import ('../views/Cami.vue'),
    meta: {
      title: '卡密列表',
    }
  }, {
    path: '/CamiAdd',
    name: 'CamiAdd',
    component: () => import ('../views/CamiAdd.vue'),
    meta: {
      title: '添加卡密',
    }
  },{
    path: '/Category',
    name: 'Category',
    component: () => import ('../views/Category.vue'),
    meta: {
      title: '品牌列表',
    }
  }, {
    path: '/CategoryAdd',
    name: 'CategoryAdd',
    component: () => import ('../views/CategoryAdd.vue'),
    meta: {
      title: '添加分类',
    }
  }, {
    path: '/CategoryEdit',
    name: 'CategoryEdit',
    component: () => import ('../views/CategoryEdit.vue'),
    meta: {
      title: '分类编辑',
    }
  }, {
    path: '/Custom',
    name: 'Custom',
    component: () => import ('../views/Custom.vue'),
    meta: {
      title: '客服列表',
    }
  }, {
    path: '/CustomAdd',
    name: 'CustomAdd',
    component: () => import ('../views/CustomAdd.vue'),
    meta: {
      title: '添加客服',
    }
  }, {
    path: '/CustomEdit',
    name: 'CustomEdit',
    component: () => import ('../views/CustomEdit.vue'),
    meta: {
      title: '客服编辑',
    }
  }, {
    path: '/Member',
    name: 'Member',
    component: () => import ('../views/Member.vue'),
    meta: {
      title: '用户列表',
      keepAlive: true // 需要缓存
    }
  },
  {
    path: '/MemberEdit',
    name: 'MemberEdit',
    component: () => import ('../views/MemberEdit.vue'),
    meta: {
      title: '用户编辑'
    }
  }, {
    path: '/Material',
    name: 'Material',
    component: () => import ('../views/Material.vue'),
    meta: {
      title: '文案列表',
    }
  },
  {
    path: '/MaterialEdit',
    name: 'MaterialEdit',
    component: () => import ('../views/MaterialEdit.vue'),
    meta: {
      title: '文案编辑'
    }
  },{
    path: '/MaterialAdd',
    name: 'MaterialAdd',
    component: () => import ('../views/MaterialAdd.vue'),
    meta: {
      title: '文案添加'
    }
  }, {
    path: '/MaterialCategory',
    name: 'MaterialCategory',
    component: () => import ('../views/MaterialCategory.vue'),
    meta: {
      title: '文案类型列表',
    }
  },
  {
    path: '/MaterialCategoryEdit',
    name: 'MaterialCategoryEdit',
    component: () => import ('../views/MaterialCategoryEdit.vue'),
    meta: {
      title: '文案类型编辑'
    }
  },{
    path: '/MaterialCategoryAdd',
    name: 'MaterialCategoryAdd',
    component: () => import ('../views/MaterialCategoryAdd.vue'),
    meta: {
      title: '文案类型添加'
    }
  }, {
    path: '/Order',
    name: 'Order',
    component: () => import ('../views/Order.vue'),
    meta: {
      title: '订单管理',
    }
  },
  {
    path: '/System',
    name: 'System',
    component: () => import ('../views/System.vue'),
    meta: {
      title: '系统设置'
    }
  },{
    path: '/Member',
    name: 'Member',
    component: () => import ('../views/Member.vue'),
    meta: {
      title: '用户列表',
    }
  },{
    path: '/MemberShip',
    name: 'MemberShip',
    component: () => import ('../views/MemberShip.vue'),
    meta: {
      title: '会员列表',
    }
  },{
    path: '/Message',
    name: 'Message',
    component: () => import ('../views/Message.vue'),
    meta: {
      title: '消息列表',
    }
  },{
    path: '/MessageAdd',
    name: 'MessageAdd',
    component: () => import ('../views/MessageAdd.vue'),
    meta: {
      title: '添加消息',
    }
  },{
    path: '/MessageEdit',
    name: 'MessageEdit',
    component: () => import ('../views/MessageEdit.vue'),
    meta: {
      title: '消息编辑',
    }
  },{
    path: '/Notice',
    name: 'Notice',
    component: () => import ('../views/Notice.vue'),
    meta: {
      title: '公告列表',
    }
  },{
    path: '/NoticeAdd',
    name: 'NoticeAdd',
    component: () => import ('../views/NoticeAdd.vue'),
    meta: {
      title: '添加公告',
    }
  },{
    path: '/NoticeEdit',
    name: 'NoticeEdit',
    component: () => import ('../views/NoticeEdit.vue'),
    meta: {
      title: '编辑公告',
    }
  },{
    path: '/File',
    name: 'File',
    component: () => import ('../views/File.vue'),
    meta: {
      title: '文件列表',
    }
  },{
    path: '/FileCash',
    name: 'FileCash',
    component: () => import ('../views/FileCash.vue'),
    meta: {
      title: '垃圾桶',
    }
  },{
    path: '/Help',
    name: 'Help',
    component: () => import ('../views/Help.vue'),
    meta: {
      title: '帮助中心',
    }
  },{
    path: '/HelpEdit',
    name: 'HelpEdit',
    component: () => import ('../views/HelpEdit.vue'),
    meta: {
      title: '编辑帮助',
    }
  },{
    path: '/Product',
    name: 'Product',
    component: () => import ('../views/Product.vue'),
    meta: {
      title: '产品列表',
    }
  },{
    path: '/ProductAdd',
    name: 'ProductAdd',
    component: () => import ('../views/ProductAdd.vue'),
    meta: {
      title: '添加产品',
    }
  },{
    path: '/ProductEdit',
    name: 'ProductEdit',
    component: () => import ('../views/ProductEdit.vue'),
    meta: {
      title: '产品编辑',
    }
  },{
    path: '/Poster',
    name: 'Poster',
    component: () => import ('../views/Poster.vue'),
    meta: {
      title: '海报列表',
    }
  },{
    path: '/PosterAdd',
    name: 'PosterAdd',
    component: () => import ('../views/PosterAdd.vue'),
    meta: {
      title: '新增海报',
    }
  },{
    path: '/PosterEdit',
    name: 'PosterEdit',
    component: () => import ('../views/PosterEdit.vue'),
    meta: {
      title: '编辑海报',
    }
  },{
    path: '/Promote',
    name: 'Promote',
    component: () => import ('../views/Promote.vue'),
    meta: {
      title: '推广列表',
    }
  },{
    path: '/PromoteAdd',
    name: 'PromoteAdd',
    component: () => import ('../views/PromoteAdd.vue'),
    meta: {
      title: '新增推广位',
    }
  },{
    path: '/PromoteEdit',
    name: 'PromoteEdit',
    component: () => import ('../views/PromoteEdit.vue'),
    meta: {
      title: '编辑推广位',
    }
  },{
    path: '/PromoteStatic',
    name: 'PromoteStatic',
    component: () => import ('../views/PromoteStatic.vue'),
    meta: {
      title: '推广统计',
    }
  },{
    path: '/HelpAdd',
    name: 'HelpAdd',
    component: () => import ('../views/HelpAdd.vue'),
    meta: {
      title: '添加帮助',
    }
  },{
    path: '/Update',
    name: 'Update',
    component: () => import ('../views/Update.vue'),
    meta: {
      title: '更新列表',
    }
  },{
    path: '/UpdateAdd',
    name: 'UpdateAdd',
    component: () => import ('../views/UpdateAdd.vue'),
    meta: {
      title: '新增更新',
    }
  },{
    path: '/Withdraw',
    name: 'Withdraw',
    component: () => import ('../views/Withdraw.vue'),
    meta: {
      title: '提现列表',
    }
  },{
    path: '/Report',
    name: 'Report',
    component: () => import ('../views/Report.vue'),
    meta: {
      title: '投诉列表',
    }
  }, {
    path: '/ReportCategory',
    name: 'ReportCategory',
    component: () => import ('../views/ReportCategory.vue'),
    meta: {
      title: '投诉列表',
    }
  }, {
    path: '/ReportCategoryAdd',
    name: 'ReportCategoryAdd',
    component: () => import ('../views/ReportCategoryAdd.vue'),
    meta: {
      title: '投诉列表',
    }
  }, {
    path: '/ReportCategoryEdit',
    name: 'ReportCategoryEdit',
    component: () => import ('../views/ReportCategoryEdit.vue'),
    meta: {
      title: '投诉列表',
    }
  },
  {
    path: '/Role',
    name: 'Role',
    component: () => import ('../views/Role.vue'),
    meta: {
      title: '角色管理',
    }
  },
  {
    path: '/RoleAdd',
    name: 'RoleAdd',
    component: () => import ('../views/RoleAdd.vue'),
    meta: {
      title: '添加角色'
    }
  },
  {
    path: '/RoleEdit',
    name: 'RoleEdit',
    component: () => import ('../views/RoleEdit.vue'),
    meta: {
      title: '角色编辑'
    }
  },{
    path: '/Vip',
    name: 'Vip',
    component: () => import ('../views/Vip.vue'),
    meta: {
      title: '会员设置',
    }
  },{
    path: '/VipEdit',
    name: 'VipEdit',
    component: () => import ('../views/VipEdit.vue'),
    meta: {
      title: '会员编辑',
    }
  },{
    path: '/VipAdd',
    name: 'VipAdd',
    component: () => import ('../views/VipAdd.vue'),
    meta: {
      title: '会员添加',
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const token = getSession('token')
  if (token) { //已经登录
    document.title = to.meta.title
    Check().then((res)=>{
      if (res.status == 0) {
        next()
      } else if (res.status == -1) {
        removeSession('token')
        removeSession('admin')
        if (to.name !== "Login") {
          next('/Login')
        }
      }
    })
  } else { // 什么都没有 去授权
    if (to.name !== "Login") {
      next('/Login')
    } else {
      next()
    }
  }
})

export default router
